import React, { Fragment, useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom';

const UserCheckout = () => { 
    const sessionId = localStorage.getItem('session_id');
    const emailConfirmationMessage = { 
        subject: '*Payment Received*', 
        message:
        `
        The following message is to confirm you, that we have received your payment successfully, within 24-48 hours, our team will contact you. 
        
        Thank you for your patience!
        `
    }

    const [showBar, setShowBar] = useState(false);
    const navigate = useNavigate();

    useEffect(() => { getClientCreds() }, []);

    const getClientCreds = async () => {
        try { 
            if (!sessionId) {
                setShowBar(false);
                setTimeout(() => {
                    navigate('/home');
                }, 3000);
                return;
            }
            const response = await fetch(`/get-session-details?session_id=${sessionId}`);
            const data = await response.json();
    
            if (response.ok) {
                executeTwilioSMSAPI(data.customer_name, data.customer_email);
                executeConfirmationMessageAPI(data.customer_email)
                setShowBar(true);
            }
        } catch (error) { 
            setShowBar(false);
            setTimeout(() => {
                navigate('/home');
            }, 3000);
            localStorage.removeItem('session_id');
        }
    }
    
    const executeTwilioSMSAPI = (name, email) => {
        if(!name && !email) return;

        const accountSid = 'AC51a1cb5148f2e4ea95791a5a4733d692';
        const authToken = '11028ed967e7fb99d50a058f9f488470';
        const fromNumber = '+16475579880';
        const toNumber = '+16477709124';
        const body = 
        `Client Checkout Successfull - Information;
            Client Name: ${name}
            Client Email: ${email}
    
            ----
            Message from Quest Sports Club Canada.
        `;
    
        const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
            },
            body: new URLSearchParams({
                From: fromNumber,
                To: toNumber,
                Body: body,
            }),
        })
        .then(response => {  
            setTimeout(() => {
                navigate('/home');
            }, 2000);
            localStorage.removeItem('session_id');
        })
        .catch(error => {
            console.log(error)
        }); 
    };

    const executeConfirmationMessageAPI = async (customer_email) => {
        try {
            const response = await fetch('/trigger-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...emailConfirmationMessage,
                    to:customer_email
                })
            });
            const data = await response.json();
    
            if (response.ok) {
                console.log(data.message)
            }
        } catch (error) {
            console.error('Error executing email to client!', error);
        } 
    }

    const success_message_text = `You have successfully made the payment, our administrator will soon contact you through your email, navigating you to our website...`;
    const fail_message_text = `You didnt made any payment, navigating you to our website...`;

    const userCheckoutModal = () => {
        return (
            <div className='user_success_checkout'>
                <div className={`${showBar ? 'success_message' : 'fail_message'}`}>{showBar ? success_message_text : fail_message_text}</div>
            </div>
        )
    };
    return (
        <Fragment> {userCheckoutModal()} </Fragment>
    )
}


export default UserCheckout; 
import React, {useState} from 'react';
import './Contact.css';
import { Settings } from '../../inc/Settings';

const Contact = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '', 
    });
    const [btnHover, setBtnHover] = useState(false)

    let contactAPIFired = false

    const onInputChangeHandler = (e) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        })
    }

    const executeSMSAPI = () => {
        if(!formData.first_name){
            Settings.alert('First Name field missing!', 'error')
            return;
        }
        if(!formData.last_name){
            Settings.alert('Last Name field missing!', 'error')
            return;
        } 
        if(!formData.phone){
            Settings.alert('Phone field missing!', 'error')
            return;
        }
        if(!formData.email){
            Settings.alert('Email field missing!', 'error')
            return;
        }
        if(!formData.message){
            Settings.alert('Email field missing!', 'error')
            return;
        }
        if(contactAPIFired){
            return;
        }

        contactAPIFired = true 
    
        const accountSid = 'AC51a1cb5148f2e4ea95791a5a4733d692';
        const authToken = '11028ed967e7fb99d50a058f9f488470';
        const fromNumber = '+16475579880';
        const toNumber = '+16477709124';
        const body = 
        `Contact - Information;

          Name: ${formData.first_name}
          Last Name: ${formData.last_name} 
          Phone: ${formData.phone}
          Email: ${formData.email} 
          Message: ${formData.message} 
 
          ----
          Message from Quest Sports Club Canada.
        `;
    
        const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;
    
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
          },
          body: new URLSearchParams({
            From: fromNumber,
            To: toNumber,
            Body: body,
          }),
        })
        .then(response => { 
            Settings.alert('Message sent successfully!')
            contactAPIFired = true 
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '', 
            })
        })
        .catch(error => {
            Settings.alert('Message couldnt Process!', 'error')
            contactAPIFired = false 
        });
    }

  return (
    <div className='contact_section' >
        <div className='contact_info_cn' id='get_in_touch'>
            <div className='container-fluid'>
                <div className='row text-lg-left text-center' >

                    <div className='col-lg-5 col-12 mb-5 ps-lg-3 p-0 m-0'>
                        <div className='contact-info m-0 mt-4'>
                            <h1>GET IN TOUCH</h1> 
                        </div>
                        <div className='contact-info m-0 mt-4'>
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-lg-2 text-right col-5 m-0'>
                                    <img className="img-fluid" src="/images/icons/contact-loc.svg" />  
                                </div>
                                <div className='col-lg-9 text-left col-7'>
                                    <div className='location_w'> 
                                        <h5>LOCATION</h5>
                                        <span>Scarborough, Pickering, Ajax, Whitby, Markham, Oshawa, Uxbridge</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='contact-info m-0 mt-4'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-lg-2 text-right col-5 m-0'>
                                    <img className='img-fluid' src='/images/icons/contact-phone.svg' />
                                </div>
                                <div className='col-lg-9 text-left col-7'>
                                    <div className='location_w'> 
                                        <h5>PHONE</h5>
                                        <span><a href='tel: +1-647-557-9880'>+1-647-557-9880</a></span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='contact-info m-0 mt-4'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-lg-2 text-right col-5 m-0'>
                                    <img className='img-fluid' src='/images/icons/contact-email.svg' />
                                </div>
                                <div className='col-lg-9 text-left col-7'>
                                    <div className='location_w'> 
                                        <h5>EMAIL</h5>
                                        <span>questsports<span>canada@<span>gmail.com</span></span></span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      
                    <div className='col-lg-7 p-0 m-0 col-12'>
                        <div className='container mt-4'>
                            <div className='row form-fields-contact'>
                                <div className='col-lg-6 col-12 fields-contact'>  
                                    <input onChange={onInputChangeHandler} value={formData.first_name} name='first_name' type="text" id="fname" placeholder='First Name' /> 
                                </div>
                                <div className='col-lg-6 col-12 fields-contact'>  
                                    <input onChange={onInputChangeHandler} value={formData.last_name} name='last_name' type="text" id="lname" placeholder='Last Name' /> 
                                </div>
                                <div className='col-lg-12 col-12 fields-contact'>  
                                    <input onChange={onInputChangeHandler} value={formData.email} name='email' type="email" id="email" placeholder={`Email`} /> 
                                </div>
                                <div className='col-lg-12 col-12 fields-contact'>  
                                    <input onChange={onInputChangeHandler} value={formData.phone} name='phone' type="text" id="phone" placeholder={`Phone`} /> 
                                </div>
                                <div className='col-lg-12 col-12 fields-contact'>  
                                    <textarea onChange={onInputChangeHandler} value={formData.message} name='message' type="text" id="message" placeholder='Message' /> 
                                </div> 
                                <button onClick={executeSMSAPI} onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn ml-3 mt-4'>Send Message <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact;
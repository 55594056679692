export const TrainingStudents = [
    {id: 1, img: '/images/creatives/training-1.png'},
    {id: 2, img: '/images/creatives/training-2.png'},
    {id: 3, img: '/images/creatives/training-3.png'}, 
    {id: 4, img: '/images/creatives/training-5.png'},
    {id: 5, img: '/images/creatives/training-6.png'},
    {id: 6, img: '/images/creatives/training-7.png'},
    {id: 7, img: '/images/creatives/training-8.png'},
    {id: 8, img: '/images/creatives/training-9.png'},
    {id: 9, img: '/images/creatives/1.jpeg'},
    {id: 10, img: '/images/creatives/2.jpeg'},
    {id: 11, img: '/images/creatives/3.jpeg'},
    {id: 12, img: '/images/creatives/4.jpeg'},
    {id: 13, img: '/images/creatives/5.jpeg'},
    {id: 14, img: '/images/creatives/6.jpeg'},
    {id: 15, img: '/images/creatives/7.jpeg'},
    {id: 16, img: '/images/creatives/8.jpeg'},
    {id: 17, img: '/images/creatives/9.jpeg'},
    {id: 18, img: '/images/creatives/10.jpeg'},
    {id: 19, img: '/images/creatives/11.jpeg'},
    {id: 20, img: '/images/creatives/12.jpeg'},
    {id: 21, img: '/images/creatives/13.jpeg'},
    {id: 22, img: '/images/creatives/14.jpeg'},
    {id: 23, img: '/images/creatives/15.jpeg'},
    {id: 24, img: '/images/creatives/16.jpeg'},
    {id: 25, img: '/images/creatives/17.jpeg'},
    {id: 26, img: '/images/creatives/18.jpeg'},
    {id: 27, img: '/images/creatives/19.jpeg'},
    {id: 28, img: '/images/creatives/20.jpeg'},
    {id: 29, img: '/images/creatives/21.jpeg'},
    {id: 30, img: '/images/creatives/22.jpeg'},
    {id: 31, img: '/images/creatives/23.jpeg'},
    {id: 32, img: '/images/creatives/24.jpeg'},
    {id: 33, img: '/images/creatives/25.jpeg'},
    {id: 34, img: '/images/creatives/26.jpeg'},
    {id: 35, img: '/images/creatives/27.jpeg'},
    {id: 36, img: '/images/creatives/28.jpeg'},
    {id: 37, img: '/images/creatives/29.jpeg'},
];
import React, {useState} from 'react';
import './Enquery.css';
import { Settings } from '../../inc/Settings';

const Enquery = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        age: '',
        grade: '',
        phone: '',
        email: '',
        running_events: null,
        combined_events: null,
        distance_events: null,
        jumps: null,
        hurdles: null,
        throws: null,
        speed_training: null,
        strength_conditioning: null,
        group_yes: null,
        group_no: null,
        message: ''
    });
    const emailConfirmationMessage = { 
        subject: '*Enquery Form Received*', 
        message:
        `
        The following message is to confirm you, that we have received your enquery successfully, within 24-48 hours, our team will contact you. 
        
        Thank you for your patience!
        `
    }
    const [btnHover, setBtnHover] = useState(false) 

    let contactAPIFired = false

    const onInputChangeHandler = (e) => {
        if(e.target.value){
            setFormData((prevState) => {
                return {
                  ...prevState,
                  [e.target.name]: e.target.value
                }
            })
        }
        if(e.target.checked){
            setFormData((prevState) => {
                return {
                  ...prevState,
                  [e.target.name]: e.target.checked
                }
            })
        }
    }
    
    const onMessageChangeHandler = (e) => { 
        setFormData((prevState) => {
            return {
              ...prevState,
              message: e.target.value
            }
        })
    }

    const handleSubmit = () => { 
        if(!formData.first_name){
            Settings.alert('First Name field missing!', 'error')
            return;
        }
        if(!formData.last_name){
            Settings.alert('Last Name field missing!', 'error')
            return;
        }
        if(!formData.age){ 
            Settings.alert('Age field missing!', 'error')
            return;
        }
        if(!formData.grade){
            Settings.alert('Grade field missing!', 'error')
            return;
        }
        if(!formData.phone){
            Settings.alert('Phone field missing!', 'error')
            return;
        }
        if(!formData.email){
            Settings.alert('Email field missing!', 'error')
            return;
        }
        if(contactAPIFired){
            return;
        }
        contactAPIFired = true 
        
        const accountSid = 'AC51a1cb5148f2e4ea95791a5a4733d692';
        const authToken = '11028ed967e7fb99d50a058f9f488470';
        const fromNumber = '+16475579880';
        const toNumber = '+16477709124';
        const body = 
        `
          Contact - Information;

          Name: ${formData.first_name}
          Last Name: ${formData.last_name}
          Age: ${formData.age}
          Grade: ${formData.grade}
          Phone: ${formData.phone}
          Email: ${formData.email}

          Interest in :

          Running Events (Track - Field): ${formData.running_events}
          Combined Events (Track - Field): ${formData.combined_events}
          Distance Events (Track - Field): ${formData.distance_events}
          Jumps Events (Track - Field): ${formData.jumps}
          Hurdles Events (Track - Field): ${formData.hurdles}
          Throws Events (Track - Field): ${formData.throws}
          -

          Speed Training (360 Athletic): ${formData.speed_training}
          Strength Conditioning (360 Athletic): ${formData.strength_conditioning}

          -
          Own Group Interest?

          ${(formData.group_no == true ? "yes" : "no") || (formData.group_yes == true ? "yes" : "no")} 

          ----
          Message from Quest Sports Club Canada.
        `;
    
        const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;
    
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
          },
          body: new URLSearchParams({
            From: fromNumber,
            To: toNumber,
            Body: body,
          }),
        })
        .then(response => { 
            Settings.alert('Message sent successfully!')
            contactAPIFired = true 
            setFormData({
                first_name: '',
                last_name: '',
                age: '',
                grade: '',
                phone: '',
                email: '',
                running_events: null,
                combined_events: null,
                distance_events: null,
                jumps: null,
                hurdles: null,
                throws: null,
                speed_training: null,
                strength_conditioning: null,
                group_yes: null,
                group_no: null,
                message: ''
            })
        })
        .catch(error => {
            Settings.alert('Message couldnt Process!', 'error')
            contactAPIFired = false 
        });

        executeConfirmationMessageAPI(formData.email)
    };

    const executeConfirmationMessageAPI = async (customer_email) => {
        try {
            const response = await fetch('/trigger-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...emailConfirmationMessage,
                    to:customer_email
                })
            });
            const data = await response.json();
    
            if (response.ok) {
                console.log(data.message)
            }
        } catch (error) {
            console.error('Error executing email to client!', error);
        } 
    };

    return (
        <div className='enquery_contact_component' id='contact-form'>
            <div className='container'>
                <div className='queries_info text-center mb-5'>
                    <h1>Quest Sports Inquiries</h1>
                    <p>Please fill out the form below and one of our team member would love to answer all your inquries</p>
                </div>

                <div className='row form-fields'>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Name<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.first_name} type="text" name='first_name' id="first_name" placeholder='First Name' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Last Name<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.last_name} name='last_name' type="text" id="last_name" placeholder='Last Name' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Age<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.age} name='age' type="text" id="name" placeholder={`Athlete's Age`} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Grade<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.grade} name='grade' type="text" id="name" placeholder={`Athlete's Grade`} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Phone<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.phone} name='phone' type="text" id="phone" placeholder='Your Phone Number' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Email Address<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.email} name='email' type="text" id="email" placeholder='Your Email Address' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-4 checkbox-types'>
                        <p>Track & Field</p>
                        <div className='row'>
                            <div className='col-6'>
                                <label>
                                    <input onChange={onInputChangeHandler} value={formData.running_events} name='running_events' type="checkbox" />
                                    <span>Running Events: <br/>100m, 200m, 400m</span>
                                </label>
                                <label>
                                    <input onChange={onInputChangeHandler} value={formData.distance_events} name='distance_events' type="checkbox" />
                                    <span>Distance Events: <br/>800m to Cross Country</span> 
                                </label>
                                <label>
                                    <input onChange={onInputChangeHandler} value={formData.hurdles} name='hurdles' type="checkbox" />
                                    <span>Hurdles: <br/>Sprint & Long</span>
                                </label> 
                            </div>
                            <div className='col-6'>
                                <label>
                                    <input onChange={onInputChangeHandler} value={formData.combined_events} name='combined_events' type="checkbox" />
                                    <span>Combined Events: <br/>Heptathlon & Decathlon</span>
                                </label>
                                <label>
                                    <input onChange={onInputChangeHandler} value={formData.jumps} name='jumps' type="checkbox" />
                                    <span>Jumps: <br/>Long, High & Triple Jump</span>
                                </label>
                                <label>
                                    <input onChange={onInputChangeHandler} value={formData.throws} name='throws' type="checkbox" />
                                    <span>Throws: <br/>Shot Put, Discuss, Javelin</span> 
                                </label>
                            </div> 
                            <div className='col-lg-12 col-12 mt-2 mb-4 checkbox-types'>
                                <p>360 Athletic</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label>
                                            <input onChange={onInputChangeHandler} value={formData.speed_training} name='speed_training' type="checkbox" />
                                        <span>Speed Training</span>
                                        </label> 
                                    </div>
                                    <div className='col-6'>
                                        <label>
                                            <input onChange={onInputChangeHandler} value={formData.strength_conditioning} name='strength_conditioning' type="checkbox" />
                                            <span>Strength & Conditioning</span>
                                        </label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-4 questions'>
                        <label>Any questions?</label>
                        <div>
                            <textarea value={formData.message} onChange={onMessageChangeHandler} name='message' id="client_questions" placeholder='Type your Questions' />
                        </div>
                    </div> 
                    <div className='col-lg-6 col-12 mb-3 bring_friends_section'>
                        <h2>BRING YOUR FRIENDS!</h2>
                        <p>It’s good to bring your friends! 
                        You can create your group, and we'll go ahead and organize your sport development pathway. 
                        </p>
                    </div>
                    <div className='col-lg-6 col-12 mb-3'>
                        <p className='group'>Do you want to make your own group?</p>
                        <div className='group_checkboxes'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-4 col-4'>
                                    <label>
                                        <input onChange={onInputChangeHandler} value={formData.group_yes} name='group_yes' type="checkbox" />
                                        Yes
                                    </label>
                                </div>
                                <div className='col-lg-6 col-md-4 col-6'>
                                    <label>
                                        <input onChange={onInputChangeHandler} value={formData.group_no} name='group_no' type="checkbox" />
                                        No
                                    </label>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='col-12'> 
                        <button onClick={handleSubmit} onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn'>SUBMIT <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Enquery;
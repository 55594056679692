import React, { useEffect, useState } from 'react';
import { Tweet } from 'react-twitter-widgets';
import './BlogsComp.css';
import { TwitterTweets } from '../Constants/TwitterTweets';
import Loader from '../Loader/Loader';

const SocialMedia = () => {
    const [btnHover, setBtnHover] = useState(false);
    const [shuffledTweets, setShuffledTweets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let customOrder = [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

        let shuffledArray = customOrder.map(index => TwitterTweets[index]);
        setShuffledTweets(shuffledArray);  
    }, []);

    const EnquerySlide = (e) => {
        e.preventDefault();
        document.querySelector("#contact-form").scrollIntoView({
            behavior: "smooth",
        });
    }

    return (
        <div className='social_media_post'>
            <div className='container p-0'>
                <div className='row d-flex justify-content-center align-items-center p-0 m-0'>
                    <div className='col-lg-6 col-md-12 col-12 mb-5'>
                        <div className="card heading-crd">
                            <h1>Stay Tuned with Social Channels!</h1>
                            <p>Join Quest Sports club private community for students & parents to keep up to date with the latest exciting news, tips, and tricks.</p>
                            <a> <button onClick={EnquerySlide} onMouseEnter={() => { setBtnHover(true); }} onMouseLeave={() => { setBtnHover(false); }} className='skew-btn'> JOIN US <span><img src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} className='img-fluid join-btn ml-2' /></span></button></a>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-12 col-12 mb-4'>
                        <div className="card-twt">
                            <div className="tweet-container">
                                {isLoading ? <Loader /> : ''}
                                {shuffledTweets.map(twt => (
                                    <Tweet onLoad={() => setIsLoading(false)} key={twt.id} tweetId={twt.tweet} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialMedia;
import React, { Fragment } from 'react';
import './Hero.css'; 

const RegularHero = ({text, about=false,  anotherText, paraTrackField=false, paraStrengthConditioning=false, paraSpeedTraining=false, backgroundImage, highlightedText, breakLine, endHighlightedText, middleHighlightedText, startHighlightedText}) => {

  const renderImageBasedOnMode = (currImage) => {
    if(currImage == '/images/creatives/banner_slider_2.jpg' && window.innerWidth <= 620) return '/images/creatives/flag_mob.jpg';
    
    return currImage;
  };

  const EnquerySlide = (e) => {
    e.preventDefault();
    document.querySelector("#contact-form").scrollIntoView({
      behavior: "smooth",
    });
  }

  return (
    <div style={{backgroundImage: `url(${renderImageBasedOnMode(backgroundImage)})`, height: !paraTrackField && !paraSpeedTraining && !paraStrengthConditioning && !about ? '500px' : '800px', backgroundColor: `${!backgroundImage && '#000'}`}} className='quest_sp_banner_regular_component d-flex justify-content-start align-items-center'>
      <div className='content-wrapper container'>
        <div className='container slide-in text-center'>
          <div className='row'>
            <div className='col-12'> 
                {!paraTrackField && <h1>{startHighlightedText && (<span>{highlightedText}</span>)} {text} {middleHighlightedText && (<span>{highlightedText}</span>)} {anotherText} {breakLine && (<br/>)} {endHighlightedText && (<span>{highlightedText}</span>)}</h1> }
                
                {paraTrackField && <h1>INDOOR { window.innerWidth <= 505 && <br/> } TRACK & FIELD { window.innerWidth <= 505 && <br/> } REGISTRATION IS NOW OPEN</h1>}
                
                {paraTrackField && 
                  <Fragment>
                    <p style={{color: '#fff', fontWeight: 500, marginBottom: '0'}}>Program runs for 4 months</p>
                    <div className='d-flex justify-content-center align-items-center'>
                      <a className='nav-link'> <button onClick={EnquerySlide} className='skew-btn'>Elementary Registration</button> </a>
                      <a className='nav-link'> <button onClick={EnquerySlide} className='skew-btn'>Highschool Registration</button> </a>
                    </div> 
                  </Fragment> 
                }
 
                {paraSpeedTraining && 
                  <Fragment>
                    <p style={{color: '#fff', fontWeight: 500, marginBottom: '0'}}>Program runs for 4 months</p>
                    <a className='nav-link'> <button onClick={EnquerySlide} className='skew-btn'>REGISTER NOW</button> </a>
                  </Fragment> 
                }

                {paraStrengthConditioning && 
                  <Fragment>
                    <p style={{color: '#fff', fontWeight: 500, marginBottom: '0'}}>High Performance & Youth Programs</p>
                  </Fragment>
                }

                {about &&
                  <Fragment>  
                    <p style={{fontWeight: 600, fontSize: '1rem', color:'#fff'}}>Sydney Mclaughlin Lavourne has been chosen as Quest Sports Track & Field Club's role model because she embodies values and beliefs that “All Things Are Possible.” As an athlete, Sydney demonstrates humility, talent, strong work ethic, character, inner strength and inner beauty. At Quest Sports Track & Field Club, we strive to embody these principles.</p>
                    <a
                      className={`nav-link`}
                      href="https://www.youtube.com/watch?v=OisAPgJCBLk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className='skew-btn'>LEARN MORE</button>
                    </a>
                  </Fragment>
                }
              </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegularHero;
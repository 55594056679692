import React, {useState, useEffect, Fragment} from 'react'
import Associations from '../components/Associations Slider/Associations';
import { BackgroundArr } from '../components/Constants/BackgroundArr';
import CutImageContent from '../components/Cutted Image Content/CutImageContent';
import RegularHero from '../components/Hero-Section/RegularHero'; 
import Loader from '../components/Loader/Loader';
import MeetFounder from '../components/Meet Founder/MeetFounder';
import Accomplishments from '../components/Accomplishments/Accomplishments';
import Team from '../components/Our Team/Team';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';
import ClockRadial from '../components/Radial Image Content/ClockRadial';
import { Helmet } from 'react-helmet'; 

const About = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const AboutPageModule = () => {
    return (
      <Fragment>
      <Helmet>
        <title>Best Track and Field Club in Ontario</title>
        <meta name="description" content="Unleash your athletic potential at Quest Sports Academy, where champions are made. Our world-class facilities and expert coaches ensure you reach new heights in your chosen sport. Join us on an incredible journey of growth, skill development, and triumph" />
      </Helmet>
      <div className='quest_sp_about_page'>
        <RegularHero 
          backgroundImage= {'/images/creatives/banner_slider_2.jpg'} 
          startHighlightedText={true}
          highlightedText={'WE BELIEVE'}
          breakLine={true} 
          about={true}
          text={'IN THE IMPOSSIBLE'}  
        />
        <MeetFounder />  
        <Accomplishments backgroundImage={'/images/creatives/accomplishment-banner.png'} />
        <ClockRadial
          heading={`OUR COACHES`}
          paragraph={`Quest Sports has produced some the top athletes in Canada. Armed with some Canada's top Olympians, highly certified coaches, provincial and national 
          level athletes and high school champions, Quest Sports is the place to be!`}
          imgUrl={`/images/creatives/clock.png`} 
          listItems={BackgroundArr} 
        />
        <CutImageContent 
          // highlightedParagraph={`WEIGHTS ARE SAFE & BENEFICIAL FOR KIDS-UNDER QUALIFIED SUPERVISION`}
          heading={`QUEST SPORTS PERFORMANCE GROUP`}
          paragraph={`Welcome to one of Ontario's premier youth and junior development programs. We have designed a sports culture that supports athletes who wish to train and develop beyond their expectations. We develop athletes from the ground up, covering all aspects of athletic development.`} 
          buttonTitle={`Inquire Now`}
          swoosh= {false}
          imgUrl={`/images/creatives/img-content-3.webp`}
          listItems={['Track & Field', 'Strength & Conditioning', 'The Speed Training Lab']}
        />
        <RadialImgContent 
          heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
          paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
          who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
          sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
          Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
          buttonTitle={`Inquire Now`}
          buttonIcon='/images/icons/golden-torch.svg'
          hoverBtnIcon='/images/icons/black-torch.svg'
          imgUrl={`/images/creatives/teenage-girl-professional-runner.png`}
        />
        <Team />  
        <Associations />
      </div>
    </Fragment>
    )
  }

  return (
    <Fragment>
      {loading ? <Loader /> : AboutPageModule()}
    </Fragment>
  )
}

export default About;